import React, { useRef, useState, useEffect } from "react";
import MapContact from "../map-contact";

const AccordionItem = ({ title, address, phone, email, map, isOpen, onClick }) => {
  const contentHeight = useRef(null); // Initialize the ref with null
  const [contentStyle, setContentStyle] = useState({ height: isOpen ? "auto" : "0px" });

  useEffect(() => {
    if (contentHeight.current) {
      setContentStyle({
        height: isOpen ? `${contentHeight.current.scrollHeight}px` : "0px"
      });
    }
  }, [isOpen]);

  const addressSplit = address.split(',')[0] + ', '
  const addressRemaining = address.split(',').slice(1).join(',') 

  return (
    <div className="overflow-hidden">
      <button
        className={`flex items-center w-full justify-between text-xl uppercase font-semibold ${
          isOpen ? "active" : ""
        }`}
        onClick={onClick}
      >
        <p className="question-content">{title}</p>
        <span>{isOpen ? "-" : "+"}</span>
      </button>

      <div ref={contentHeight} style={contentStyle} className="transition-all duration-300">
        <div className="text-sm py-10 space-y-6">
          <p>
            {addressSplit} <br />
            {addressRemaining}
          </p>
          <p>
            <a href={`tel:${phone}`}>{phone}</a> <br />
            <a href={`mailto:${email}`}>{email}</a>
          </p>
        </div>
        { isOpen && (
          <div className="aspect-[330/500] py-4 lg:hidden lg:py-0 lg:aspect-auto">
            <MapContact longLat={map}/> 
          </div>
        )}
      </div>
    </div>
  );
};

export default AccordionItem;
