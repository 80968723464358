// map.js
import React, { useRef, useEffect, useState } from "react"
import mapboxgl from "!mapbox-gl"
import "mapbox-gl/dist/mapbox-gl.css"

const MAPBOX_TOKEN = "pk.eyJ1IjoibGJkc3R1ZGlvczEiLCJhIjoiY2w5dzR6eTYxMmxxOTNvbzVhZGRwNTM0NyJ9.ZQ1nAHjOv-k3WMZO_9rHew"

const mapContainerStyle = {
  width: "100%",
  height: "100%",
}

const MapContact = ({longLat}) => {
  const mapContainerRef = useRef(null)

  const [map, setMap] = useState(null)

//   const longLat = isNewZealand ? [174.7645, -36.8509] : [144.9961971, -37.8398392];

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      accessToken: MAPBOX_TOKEN,
      style: "mapbox://styles/mapbox/dark-v10",
      // Empire State Building [lng, lat]
      center: longLat,
      zoom: 15,
    })
    setMap(map)

    // add marker

    const marker = new mapboxgl.Marker({scale: 1.25,  color: "#ffffff"})
    
    // if (isNewZealand == false){
    //   marker.setLngLat(longLat).addTo(map)
    // }
    marker.setLngLat(longLat).addTo(map)

    return () => map.remove()
  }, [])

  return <div ref={mapContainerRef} style={mapContainerStyle} />
}

export default MapContact
