import React, { useEffect, useState } from "react";
import Link from "../../utils/link";
import ContactModal from "./ContactModal";
import MobileMenu from "./MobileMenu";
import { graphql, useStaticQuery } from "gatsby";
import Brandmark from "./Brandmark";
import CloseCross from "./CloseCross";
import useStoryblok from "../../lib/storyblok";

function BurgerIcon(props) {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="-0.5 0 17 14"
      className="w-full h-full"
      {...props}
    >
      <path stroke="#fff" d="M0 0.5L16 0.5" />
      <path stroke="#fff" d="M0 13.5L16 13.5" />
    </svg>
  );
}

const MenuSerializer = (props) => {
  return (
    <ul className="flex header-text">
      {props.content.map((item, index) => {
        if (item.title != "Contact") {
          return (
            <li key={`menu-item-${index}`}>
              <Link
                to={item.link.cached_url}
                className="text-white tracking-wider hover-underline-animation"
              >
                {item.title}
              </Link>
            </li>
          );
        } else {
          return (
            <li key={`menu-item-${index}`}>
              <button
                onClick={() => props.setModalOpen(true)}
                className="uppercase hover-underline-animation "
              >
                Contact
              </button>
            </li>
          );
        }
      })}
    </ul>
  );
};

const contactData = [
  {
    title: "Melbourne",
    address: "37 Forster St, South Yarra, VIC, 3141",
    phone: "1300 156 389",
    email: "hello@trustprojects.com.au",
    longlat: [144.995980, -37.840640]
  },
  {
    title: "Sydney",
    address: "383 George St, Sydney, NSW, 2000",
    phone: "1300 156 389",
    email: "hello@trustprojects.com.au",
    longlat: [151.203373, -33.902063]
  },
  {
    title: "Brisbane",
    address: "25 King Street, Bowen Hills, QLD, 4006",
    phone: "1300 156 389",
    email: "hello@trustprojects.com.au",
    longlat: [153.03387, -27.452182]
  }
]

const Header = (props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const data = useStaticQuery(graphql`
    query NavQuery {
      storyblokEntry(full_slug: { eq: "header" }) {
        id
        content
      }
      site {
        siteMetadata {
          title
          description
          author
          siteUrl
        }
      }
    }
  `);

  let story = data.storyblokEntry;

  const isNewZealand = data.site.siteMetadata.siteUrl === 'https://trustprojects.co.nz';

  story = useStoryblok(story);

  const content = story.content;

  const desktop_left = content.desktop_left;

  const desktop_right = content.desktop_right;

  const mobile_menu = content.mobile_menu;

  const contactTitle = content.contact_title;

  const contactCopy = content.contact_copy;

  useEffect(() => {
    if (modalOpen || mobileMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [modalOpen, mobileMenuOpen]);

  return (
    <>
      <header className="absolute inset-0 h-fit z-[30] text-white uppercase">
        <nav className="container-nav py-[42px] lg:py-[70px]">
          <ul className="grid grid-cols-6 lg:grid-cols-3">
            <div className="hidden lg:block">
              <MenuSerializer
                content={desktop_left}
                setModalOpen={setModalOpen}
              />
            </div>
            <li className="lg:hidden"></li>
            <div className="flex justify-center col-span-4 lg:col-span-1">
              <Link to="/" className="w-[223px] h-auto lg:w-[227px] flex items-center justify-center pt-1 lg:pt-0">
                <Brandmark />
              </Link>
            </div>
            <div className="justify-end text-right hidden lg:flex">
              <MenuSerializer
                content={desktop_right}
                setModalOpen={setModalOpen}
              />
            </div>
            <li className="justify-end text-right flex lg:hidden items-center">
              <button
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                className="w-[20px] h-[20px] relative"
              >
                <span
                  className={`transition-opacity duration-300 ${
                    mobileMenuOpen ? `opacity-0` : ``
                  }`}
                >
                  {" "}
                  <BurgerIcon />
                </span>
                <span
                  className={`absolute inset-0  transition-opacity duration-300 ${
                    !mobileMenuOpen ? `opacity-0` : ``
                  }`}
                >
                  {" "}
                  <CloseCross />
                </span>
              </button>
            </li>
          </ul>
        </nav>
      </header>
      <ContactModal isNewZealand={isNewZealand} modalOpen={modalOpen} setModalOpen={setModalOpen} contactTitle={contactTitle} contactCopy={contactCopy} contactData={contactData} />
      <MobileMenu
        open={mobileMenuOpen}
        set={setMobileMenuOpen}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        menuItems={mobile_menu}
      />
    </>
  );
};

export default Header;
